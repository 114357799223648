var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ 'tfw-job-board': _vm.isTFWPublicJobBoard }},[(_vm.isFetchingGenericFilter)?_c('LoadingOverlay'):_vm._e(),_c('GenericFilter',{attrs:{"basicFilterlength":_vm.getFilterSchema.basicFilterLength,"filterSchema":_vm.getFilterSchema.schema,"selectedFilters":_vm.selectedFilters,"filterDependencyObj":_vm.filterDependencyObj,"isFetchingFilter":_vm.isFetchingGenericFilter,"callback":_vm.updateRouterURL,"searchCallback":_vm.searchCallback,"filterAction":_vm.filterAction,"backURL":_vm.backURL}}),_c('div',{staticClass:"job-board"},[(_vm.isMobile && _vm.jobAdvertisement == true)?_c('div',[_c('advertisement',{attrs:{"allign":'horizontal'}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row"},[(
          !_vm.isFetchingGenericFilter &&
          !_vm.isJobBoardFetching &&
          !_vm.getJobBoard.length
        )?_c('div',{staticClass:"d-flex flex-row w-100"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin-right":"20%"}},[(_vm.getJobCount)?_c('h5',{staticClass:"count"},[_vm._v(" Total Jobs: "+_vm._s(_vm.getJobCount)+" ")]):_vm._e(),_c('h5',{staticClass:"count"},[_vm._v("Search Results: "+_vm._s(_vm.getFilterCount))])]),_c('div',{staticClass:"d-flex flex-column mt-5"},[_c('h4',[_vm._v(" No jobs matched the selected criteria "),(_vm.AddJobRoles.includes(_vm.currentUserRole))?_c('span',[_vm._v(", Please add a Job")]):_vm._e()]),(_vm.AddJobRoles.includes(_vm.currentUserRole))?_c('CButton',{staticClass:"btn-primary",attrs:{"title":"Add Job"},on:{"click":function($event){return _vm.navigateToAddJob()}}},[_vm._v("Add Job")]):_vm._e()],1)]):_vm._e(),(
          !(
            !_vm.isFetchingGenericFilter &&
            !_vm.isJobBoardFetching &&
            !_vm.getJobBoard.length
          )
        )?_c('div',{staticClass:"job-board-list col-md-4",attrs:{"id":"job-board-body"}},[_c('div',[(_vm.getJobCount)?_c('h6',{staticClass:"count"},[_vm._v(" Total Jobs: "+_vm._s(_vm.getJobCount)),_c('span',{staticClass:"pl-3"}),_vm._v(" Search Results: "+_vm._s(_vm.getFilterCount)+" ")]):_vm._e()]),_vm._l((_vm.getJobBoard),function(job,index){return _c('job-card',{key:index,class:!_vm.isMobile &&
            _vm.getSelectedJobBoard &&
            _vm.getSelectedJobBoard.job_id == job.job_id
              ? 'highlight'
              : 'disabled',attrs:{"index":index,"item":job},nativeOn:{"click":function($event){return _vm.jobCardSelectedCallback(job)}}})}),(_vm.noMoreDataFromJobBoard)?_c('div',{staticClass:"no-more-data"},[(_vm.getJobBoard.length > 0)?_c('p',{staticClass:"btn-primary"},[_vm._v(" No More Data! ")]):_vm._e()]):_vm._e(),(!_vm.noMoreDataFromJobBoard)?_c('div',{staticClass:"d-flex justify-content-around align-items-center has-loading-overlay",staticStyle:{"height":"200px"}},[(_vm.isJobBoardFetching)?_c('LoadingOverlay'):_vm._e()],1):_vm._e()],2):_vm._e(),(_vm.getJobBoard.length > 0 || _vm.isJobBoardFetching)?_c('div',{staticClass:"job-board-detail col-md-6",class:{ 'col-md-8': _vm.jobAdvertisement == false }},[_c('CModal',{attrs:{"id":"applyjobModal","title":"Apply Job","color":"primary","centered:false":"","show":_vm.successModal},on:{"update:show":[_vm.onShow,function($event){_vm.successModal=$event}]}},[_vm._v(_vm._s(this.modalMessage))]),(!(_vm.getSelectedJobBoard && _vm.getSelectedJobBoard.job_id))?_c('div',{staticClass:"skeleton-card"}):_c('job-detail',{attrs:{"getSelectedJobBoard":_vm.getSelectedJobBoard}})],1):_vm._e(),(_vm.isMobile != true && _vm.jobAdvertisement == true)?_c('div',{staticClass:"col-lg-2",staticStyle:{"padding-right":"0px"}},[_c('advertisement',{attrs:{"allign":'verticle'}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }