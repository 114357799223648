var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isFetchingGenericFilter)?_c('LoadingOverlay'):_vm._e(),(!_vm.isAdmins)?_c('div',{staticClass:"text-right mt-3"},[_c('router-link',{attrs:{"to":"/home"}},[_c('CButton',{staticClass:"spacingHomeBtn spacingHomeBtnRes",attrs:{"color":"primary","shape":"pill"}},[_vm._v("Back to Home")])],1)],1):_vm._e(),_c('CRow',[(_vm.page != 'publicJobBoard' && _vm.routeJobStatus)?_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"d-flex h-100 align-items-center"},[_c('h6',{staticClass:"text-primary"},[_vm._v(_vm._s(_vm.routeJobStatus)+" Jobs >>")])])]):_vm._e(),_c('CCol',{attrs:{"md":_vm.page === 'publicJobBoard' && !_vm.routeJobStatus ? 12 : 10}},[_c('GenericFilter',{attrs:{"basicFilterlength":_vm.basicFilterLength,"filterSchema":_vm.currentSchema,"selectedFilters":_vm.selectedFilters,"filterDependencyObj":_vm.filterDependencyObj,"isFetchingFilter":_vm.isFetchingGenericFilter,"callback":_vm.updateRouterURL,"searchCallback":_vm.searchCallback,"filterAction":_vm.filterAction}})],1)],1),_c('div',{staticClass:"job-board"},[_c('div',{staticClass:"d-flex flex-row"},[(
          !_vm.isFetchingGenericFilter &&
          !_vm.isJobBoardFetching &&
          !_vm.getJobBoard.length
        )?_c('div',{staticClass:"d-flex flex-row w-100"},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"margin-right":"20%"}},[_c('h5',{staticClass:"count"},[_vm._v("Total Jobs: "+_vm._s(_vm.getJobCount))]),_c('h5',{staticClass:"count"},[_vm._v("Search Results: "+_vm._s(_vm.getFilterCount))])]),(this.$router.currentRoute.params.jobStatus == 'active')?_c('div',{staticClass:"d-flex flex-column mt-5"},[_c('h4',[_vm._v(" No jobs matched the selected criteria "),(_vm.AddJobRoles.includes(_vm.currentUserRole))?_c('span',[_vm._v(", Please add a Job")]):_vm._e()]),(_vm.AddJobRoles.includes(_vm.currentUserRole))?_c('CButton',{staticClass:"btn-primary",attrs:{"title":"Add Job"},on:{"click":function($event){return _vm.navigateToAddJob()}}},[_vm._v("Add Job")]):_vm._e()],1):_c('div',{staticClass:"d-flex flex-column mt-5"},[_c('h4',[_vm._v("Currently there are no Jobs")])])]):_vm._e(),_c('div',{class:{
          'job-board-list col-lg-4': !(
            !_vm.isFetchingGenericFilter &&
            !_vm.isJobBoardFetching &&
            !_vm.getJobBoard.length
          ),
        },attrs:{"id":"job-board-body"}},[(
            !(
              !_vm.isFetchingGenericFilter &&
              !_vm.isJobBoardFetching &&
              !_vm.getJobBoard.length
            )
          )?_c('div',{staticClass:"d-flex"},[_c('h6',{staticClass:"count mr-3"},[_vm._v(" Total Jobs: "+_vm._s(_vm.getJobCount)+" Search Results: "+_vm._s(_vm.getFilterCount)+" ")])]):_vm._e(),_vm._l((_vm.getJobBoard),function(job,index){return _c('job-card',{key:index,class:!_vm.isMobile &&
            _vm.getSelectedJobBoard &&
            _vm.getSelectedJobBoard.job_id == job.job_id
              ? 'highlight'
              : '',attrs:{"index":index,"item":job},nativeOn:{"click":function($event){return _vm.jobCardSelectedCallback(job)}}})}),(_vm.noMoreDataFromJobBoard)?_c('div',{staticClass:"no-more-data pb-5"},[(_vm.getJobBoard.length > 0)?_c('p',{staticClass:"btn-primary"},[_vm._v(" No More Data! ")]):_vm._e()]):_vm._e(),(!_vm.noMoreDataFromJobBoard)?_c('div',{staticClass:"d-flex justify-content-around align-items-center has-loading-overlay",staticStyle:{"height":"100px"}},[(_vm.isJobBoardFetching)?_c('LoadingOverlay'):_vm._e()],1):_vm._e()],2),(_vm.getJobBoard.length > 0 || _vm.isJobBoardFetching)?_c('div',{staticClass:"job-board-detail right-main-container pb-4 col-md-8"},[(!(_vm.getSelectedJobBoard && _vm.getSelectedJobBoard.job_id))?_c('div',{staticClass:"skeleton-card"}):_c('jobDetail',{attrs:{"getSelectedJobBoard":_vm.getSelectedJobBoard}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }